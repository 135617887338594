.App {
  text-align: center;
  background-color: red;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: candara;
  src: url(/public/Candara.ttf);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.awssld__controls__arrow-right::before{
  background-color: white !important;
}
.awssld__controls__arrow-right::after{
  background-color: white !important;
}
.awssld__controls__arrow-left::before{
  background-color: white !important;
}
.awssld__controls__arrow-left::after{
  background-color: white !important;
}
.awssld__bullets{
  visibility: hidden;
}
.menu a:hover {
    background-color: #FFF2D8;
    color:black !important;
    
}
.menu a {
    width: 140px;
    display: inline-block;
    text-align: center;
}

.slider-button:hover{
    background-color: #FFF2D8 !important;
    color:black !important;
}